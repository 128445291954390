import {convertBxAttributes} from "../../utils/bxAttributes";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Mousewheel, Pagination} from "swiper/modules";
import React from "react";
import ProductTile from "../product/Tile";
import single from "../../utils/single";
import Link from "../link/Link";

interface Props {
    narrative: any;
}

const breakpoints = {
    1: {
        slidesPerView: 1
    },
    768: {
        slidesPerView: 2
    },
    992: {
        slidesPerView: 3
    },
    1200: {
        slidesPerView: 4
    },
} as const;

function ContentBox({ block }: any) {
    return <div style={{background: '#e3f2f6'}} className="h-100 px-4 text-center d-flex flex-column align-items-center justify-content-center">
        <div className="fw-medium fs-4 mb-3">
            {single(block.title)}
        </div>
        <div className="mb-4">
            {single(block.description)}
        </div>
        <Link className="btn btn-black fw-semibold text-white fs-7 w-90 py-2" href={single(block.link)}>{single(block.cta)}</Link>
    </div>;
}

export default function BxContentBoxProductSlider({ narrative }: Props) {
    const [contentBox, productSlider] = narrative.blocks;

    const title = single(narrative.title);
    return <div {...convertBxAttributes(productSlider, 'bg-highlight mt-1')}>
        <div className="bg-white pt-4 pb-4 container">
            {title && (
                <>
                    <h2 className="mb-3">{title}</h2>
                    <hr className="primary-color"/>
                </>
            )}

            <Swiper pagination={{clickable: true}}
                    breakpoints={breakpoints}
                    mousewheel={{forceToAxis: true}}
                    freeMode={{sticky: true}}
                    modules={[Pagination, Mousewheel, FreeMode]}
                    className="product-slider pb-4"
                    preventClicks={true}
                    loop={false}>
                {/* auto height required for align-items-stretch to work */}
                <SwiperSlide className="h-auto" >
                    <ContentBox block={contentBox} />
                </SwiperSlide>
                {productSlider.blocks.map((product: any, index: number) => (
                    <SwiperSlide key={index}>
                        <ProductTile product={product['bx-hit'] ?? product}/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    </div>;
}
